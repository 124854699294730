import { useState } from "react";
import { useSelector } from "react-redux"
import { currency } from "../helper"

function CarBox({ data,modelShow }) {
  const [carLoad, setCarLoad] = useState(true);
  const { coin } = useSelector((state) => state.data)
  return (
    <>
      
        <div className="box-cars">
          {/* car */}
          <div className="pick-car">
            {carLoad && <span className="loader"></span>}
            <img
              style={{ display: carLoad ? "none" : "block" }}
              src={data.img[0]}
              alt="car_img"
              onLoad={() => setCarLoad(false)}
            />
          </div>
          {/* description */}
          <div className="pick-description">
            <div className="pick-description__price">
              <span>{currency(data.price,coin)} {coin}</span>/ jour
            </div>
            <div className="pick-description__table">
              <div className="pick-description__table__col">
                <span>Modèle</span>
                <span>{data.model}</span>
              </div>

              <div className="pick-description__table__col">
                <span>Marque</span>
                <span>{data.mark}</span>
              </div>

              {/* <div className="pick-description__table__col">
                <span>Année</span>
                <span>{data.model}</span>
              </div> */}

              <div className="pick-description__table__col">
                <span>Portes</span>
                <span>{data.doors}</span>
              </div>

              {/* <div className="pick-description__table__col">
                <span>type</span>
                <span>
                <i style={{fontSize:"20px"}} className="material-symbols-outlined"> {data.type === "car" ? "directions_car" : "two_wheeler"} </i>
                  
                  </span>
              </div> */}

              <div className="pick-description__table__col">
                <span>Transmission</span>
                <span>{data.transmission}</span>
              </div>

              <div className="pick-description__table__col">
                <span>Carburant</span>
                <span>{data.fuel}</span>
              </div>
            </div>
            {/* btn cta */}
            <button className="cta-btn" onClick={modelShow}>
            Réservez  maintenant
            </button>
          </div>
        </div>
      
    </>
  );
}

export default CarBox;
