import { IconChevronDown } from "@tabler/icons-react";
import { useState } from "react";

function Faq() {
  const [activeQ, setActiveQ] = useState("q1");

  const openQ = (id) => {
    setActiveQ(activeQ === id ? "" : id);
  };

  const getClassAnswer = (id) => {
    return activeQ === id ? "active-answer" : "";
  };

  const getClassQuestion = (id) => {
    return activeQ === id ? "active-question" : "";
  };

  return (
    <>
      <section className="faq-section">
        <div className="container">
          <div className="faq-content">
            <div className="faq-content__title">
              <h5>FAQ</h5>
              <h2>Foire Aux Questions Fréquentes</h2>
              <p>
                Bienvenue dans notre section FAQ, où nous répondons aux
                questions fréquemment posées pour vous fournir toutes les
                informations dont vous avez besoin.
              </p>
            </div>

            <div className="all-questions">
              <div className="faq-box">
                <div
                  id="q1"
                  onClick={() => openQ("q1")}
                  className={`faq-box__question  ${getClassQuestion("q1")}`}
                >
                  <p>
                    1. Comment puis-je réserver une voiture avec Kinane Voyage ?
                  </p>
                  <IconChevronDown />
                </div>
                <div
                  id="q1"
                  onClick={() => openQ("q1")}
                  className={`faq-box__answer ${getClassAnswer("q1")}`}
                >
                  Pour réserver votre voiture, rendez-vous sur notre site web,
                  sélectionnez le modèle de votre choix, indiquez vos dates de
                  location, et suivez les étapes simples du processus de
                  réservation en ligne.
                </div>
              </div>
              <div className="faq-box">
                <div
                  id="q2"
                  onClick={() => openQ("q2")}
                  className={`faq-box__question ${getClassQuestion("q2")}`}
                >
                  <p>
                    2. Quels types de véhicules proposez-vous dans votre flotte
                    de location ?
                  </p>
                  <IconChevronDown />{" "}
                </div>
                <div
                  id="q2"
                  onClick={() => openQ("q2")}
                  className={`faq-box__answer ${getClassAnswer("q2")}`}
                >
                  Nous offrons une gamme variée de véhicules, des voitures
                  compactes aux SUV spacieux. Vous pouvez explorer notre flotte
                  complète dans la section "Modèles de Véhicules" de notre site.
                </div>
              </div>
              <div className="faq-box">
                <div
                  id="q3"
                  onClick={() => openQ("q3")}
                  className={`faq-box__question ${getClassQuestion("q3")}`}
                >
                  <p>3. Quels sont les tarifs de location ?</p>
                  <IconChevronDown />
                </div>
                <div
                  id="q3"
                  onClick={() => openQ("q3")}
                  className={`faq-box__answer ${getClassAnswer("q3")}`}
                >
                  Les tarifs de location varient en fonction du modèle de
                  voiture, de la durée de location et d'autres options. Pour
                  obtenir un devis précis, utilisez notre outil de réservation
                  en ligne ou contactez notre service client. bas.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Faq;
