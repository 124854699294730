import MainImg from "../images/chooseUs/main.png";
import Box1 from "../images/i/i-3.png";
import Box2 from "../images/i/i-1.png";
import Box3 from "../images/i/i-2.png";
import { IconChevronRight } from "@tabler/icons-react";

function ChooseUs() {
  return (
    <>
      <section className="choose-section">
        <div className="container">
          <div className="choose-container">
            <img
              className="choose-container__img"
              src={MainImg}
              alt="car_img"
            />
            <div className="text-container">
              <div className="text-container__left">
                <h4>Pourquoi nous choisir</h4>
                <h2>
                Explorez les offres imbattables que vous ne trouverez nulle part ailleurs en termes de qualité valeur
                </h2>
                <p>
                Découvrez les offres inégalées que vous ne trouverez nulle part ailleurs grâce à nos offres imbattables. Nous nous engageons à vous offrir le meilleur rapport qualité-prix, vous permettant de bénéficier de services et de produits de première qualité sans compromettre votre budget. Nos offres sont minutieusement élaborées pour vous offrir l'expérience de location ultime.
                </p>
                <a href="#home">
                Trouver des détails &nbsp;
                  <IconChevronRight />
                </a>
              </div>
              <div className="text-container__right">
                <div className="text-container__right__box">
                  <img src={Box1} alt="car-img" />
                  <div className="text-container__right__box__text">
                    <h4>Voyage à travers le pays</h4>
                    <p>
                    Portez votre expérience de conduite à un niveau supérieur avec nos véhicules haut de gamme pour vos aventures à travers le pays
                    </p>
                  </div>
                </div>
                <div className="text-container__right__box">
                  {" "}
                  <img src={Box2} alt="coin-img" />
                  <div className="text-container__right__box__text">
                    <h4>Tarification tout compris</h4>
                    <p>
                    Obtenez tout ce dont vous avez besoin à un prix pratique et transparent grâce à notre politique de tarification tout compris
                    </p>
                  </div>
                </div>
                <div className="text-container__right__box">
                  {" "}
                  <img src={Box3} alt="coin-img" />
                  <div className="text-container__right__box__text">
                    <h4>Aucuns frais cachés</h4>
                    <p>
                     Profitez de la tranquillité d'esprit avec notre politique sans frais cachés. Nous croyons en une tarification transparente et honnête .
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ChooseUs;
