import support from "../images/i/support.png"

function Banner() {
  

  return (
    <>
      <section className="banner-section">
        <div className="container">
          <div className="banner-content">
            <div className="banner-content__text">
              <h2>Économisez gros avec notre location de voiture bon marché</h2>
              <p>
              Principaux aéroports. Fournisseurs locaux. Support <span>24/7</span> 

              </p>
            </div>
          </div>
          <div className="icone_location">
            <img src={support} alt="support" width="100%" />
          </div>
        </div>
      </section>
    </>
  );
}

export default Banner;
