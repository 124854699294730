export function lenText(txt, n) {
    return txt.split('').slice(0, n).join('') + '..'
}

export const typeDateHelprer = (da) => {
    const inputDate = new Date(da)

    const year = inputDate.getFullYear()
    const month = String(inputDate.getMonth() + 1).padStart(2, '0')
    const day = String(inputDate.getDate()).padStart(2, '0')

    const formattedDate = `${year}-${month}-${day}`

    return formattedDate
}

export const toBottomx = () => {
    const scrolll = document.documentElement

    if (scrolll.scrollTop < scrolll.clientHeight) {
        window.scrollTo(0, scrolll.clientHeight - 188)
    }
}

export const totalDays = (a, b) => {
    var startDate = new Date(a)

    var endDate = new Date(b)

    var difference = endDate.getTime() - startDate.getTime()

    var daysDifference = Math.ceil(difference / (1000 * 60 * 60 * 24))

    return daysDifference
}

export const getFlagEmoji = (countryCode) => {
    const codePoints = countryCode
        .toUpperCase()
        .split('')
        .map((char) => 127397 + char.charCodeAt())
    return String.fromCodePoint(...codePoints)
}

export const rangeDate = (arr) => {
    // array [['2024-03-03','2024-03-25'],['2024-04-10','2024-04-20']]

    let res = [new Date()]

    arr.forEach(({ drop_date, pick_date }) => {
        let startDate = new Date(pick_date)
        let endDate = new Date(drop_date)

        for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
            res.push(new Date(date))
        }
    })

    return res
}

export function addDays(date, days) {
    var result = new Date(date)
    result.setDate(result.getDate() + days)
    return result
}

export function getDatesBetween(startDate, endDate) {
    const dates = []
    let currentDate = new Date(startDate)

    while (currentDate <= endDate) {
        dates.push(new Date(currentDate))
        currentDate.setDate(currentDate.getDate() + 1)
    }

    return dates
}

export function currency(price, coin="DH") {

    const co = coin.toUpperCase()

    if (co === 'USD') {

        return  (Number(price) / 10.06).toFixed(2) 

    }

    if (co === 'EUR') {

        return (Number(price) / 10.92).toFixed(2)

    }

    return Number(price).toFixed(2)
}

export function toMad(price,coin='MAD'){

    const co = coin.toUpperCase()

    if (co === 'USD') {

        return  (Number(price) * 10.06).toFixed(0) 

    }

    if (co === 'EUR') {

        return (Number(price) * 10.92).toFixed(0)

    }

    return Number(price).toFixed(0)

}
