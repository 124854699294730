import React, { useEffect, useState } from "react";
import "@splidejs/react-splide/css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import { Link } from "react-router-dom";


import  Axios  from "axios";



export default function SliderBrand() {


  const apiUrl = process.env.REACT_APP_API_URL;


  const opt = {
    type   : 'loop',
    drag   : 'free',
    focus  : 'center',
    perPage: 6,
    gap: "20px",
    pauseOnHover: false,
    autoScroll: {
      speed: 2,
    },
  };


  const [brands,setBands] = useState([])

  const sliderEl = brands.map(br=>{
    return (

        <SplideSlide className="slider_box" key={br.id}>
          <Link style={{ height: "100%" }} to={`/models/${br.type}/${br.name}`}>
            <img style={{ height: "50%" }} src={br.image} alt="Image 1" />
          </Link>
        </SplideSlide>

    )
  })

  useEffect(() => {
    

    Axios.get(`${apiUrl}/frontend/slider`).then(res=>{

      if(res.status === 200){

        let brands = res.data.map(el => {
          return {...el.brand,type:el.type};
        });
        
        const uniqueArray = Array.from(new Set(brands.map(brand => brand.id))).map(id => {
          return brands.find(brand => brand.id === id);
        });

        //console.log(uniqueArray)

        setBands(uniqueArray)
        
        

      }
      
    })
  },[])



  return (
    <div
      className="slider_brand"
      style={{ width: "100%", maxWidth: "1280px", margin: "0 auto 40px" }}
    >
      <Splide
        aria-label="My Favorite Images"
        options={opt}
        extensions={{ AutoScroll }}
        style={{ width: "100%", padding: "0 20px" }}
      >
        {
            sliderEl
        }
      </Splide>
    </div>
  );
}
