import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import '../dist/profile.css'
import axios from 'axios'
import { totalDays } from '../helper'

const apiUrl = process.env.REACT_APP_API_URL

function Profile() {
    const [token, setToken] = useState(undefined)
    const [user, setUser] = useState([])
    const [reservation, setReservation] = useState([])

    const navigate = useNavigate()

    useEffect(function () {
        if (Cookies.get('user')) {
            const { token } = JSON.parse(Cookies.get('user'))

            //console.log(token)

            const config = {
                headers: { Authorization: `Bearer ${token}` },
            }

            setToken(token)

            axios
                .get(`${apiUrl}/auth/user`, config)
                .then((res) => {
                    //console.log(res.data)
                    setUser(res.data)
                })
                .catch((err) => {
                    navigate('/')
                })

            axios.get(`${apiUrl}/user-rentlogs/history`, config).then((res) => {
                //console.log(res.data)
                setReservation(res.data)
            })
        } else {
            navigate('/')
        }
    }, [])

    const logout = () => {
        Cookies.remove('user')
        window.location.reload(true)
    }

    return (
        token && (
            <div className="profile_page">
                <div className="container_profile">
                    <div className="box_one box-p">
                        <div className="photoUser">
                            <i className="material-symbols-outlined">person</i>
                        </div>
                        <h3 className="nameUser">
                            {user.firstname ? user.firstname + ' ' + user.lastname : '-'}
                        </h3>
                        <div className="btnsx">
                            <button onClick={logout}>Se déconnecter</button>
                        </div>
                    </div>
                    <div className="box_two box-p">
                        <div className="row">
                            <div className="col-sm-3">
                                <i className="material-symbols-outlined">person</i>
                                <p className="mb-0">Prénom</p>
                            </div>
                            <div className="col-sm-9">
                                {/* <button className="btnEdit" onClick={editData}>
                                    <i className="material-symbols-outlined">edit</i>
                                </button> */}

                                <input
                                    type="text"
                                    name="userName"
                                    id="userName"
                                    value={user.firstname ? user.firstname : '-'}
                                    readOnly={true}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-3">
                                <i className="material-symbols-outlined">person</i>
                                <p className="mb-0">Nom</p>
                            </div>
                            <div className="col-sm-9">
                                {/* <button className="btnEdit" onClick={editData}>
                                    <i className="material-symbols-outlined">edit</i>
                                </button> */}
                                <input
                                    type="text"
                                    name="userName"
                                    id="userName"
                                    value={user.lastname ? user.lastname : '-'}
                                    readOnly={true}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-3">
                                <i className="material-symbols-outlined">badge</i>
                                <p className="mb-0">Username</p>
                            </div>
                            <div className="col-sm-9">
                                {/* <button className="btnEdit" onClick={editData}>
                                    <i className="material-symbols-outlined">edit</i>
                                </button> */}
                                <input
                                    type="text"
                                    name="userName"
                                    id="userName"
                                    value={user.username ? user.username : '-'}
                                    readOnly={true}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-3">
                                <i className="material-symbols-outlined">mail</i>
                                <p className="mb-0">Email</p>
                            </div>
                            <div className="col-sm-9">
                                {/* <button className="btnEdit">
                                    <i className="material-symbols-outlined">edit</i>
                                </button> */}
                                <input
                                    type="text"
                                    name="userName"
                                    id="userName"
                                    value={user.email ? user.email : '-'}
                                    readOnly={true}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-3">
                                <i className="material-symbols-outlined">call</i>
                                <p className="mb-0">Téléphone</p>
                            </div>
                            <div className="col-sm-9">
                                {/* <button className="btnEdit" onClick={editData}>
                                    <i className="material-symbols-outlined">edit</i>
                                </button> */}
                                <input
                                    type="text"
                                    name="userName"
                                    id="userName"
                                    value={user.mobile ? "+"+user.country_code+user.mobile : '-'}
                                    readOnly={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="box_three">
                        {reservation.length > 0 ? (
                            <table>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>From</th>
                                        <th>To</th>
                                        <th>Jours</th>
                                        <th>type</th>
                                        <th>Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {reservation.map((value, key) => {
                                        return (
                                            <tr key={key}>
                                                <td>{value.vehicle.name}</td>
                                                <td>{value.pick_time.split(' ')[0]}</td>
                                                <td>{value.drop_time.split(' ')[0]}</td>
                                                <td>
                                                    {totalDays(value.pick_time, value.drop_time)}
                                                </td>
                                                <td className="tdType">
                                                    {
                                                        <i className="material-symbols-outlined">
                                                            {value.vehicle.type == 'car'
                                                                ? 'directions_car'
                                                                : 'two_wheeler'}
                                                        </i>
                                                    }
                                                </td>
                                                <td>{`${Math.floor(value.price)} Dh`}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        ) : (
                            <div className="lodingTable">
                                <span>
                                    pas de données disponibles
                                    <i className="material-symbols-outlined">move_to_inbox</i>
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    )
}

export default Profile
