import '../src/dist/styles.css'
import '../src/dist/darkMode.css'

import Navbar from '../src/components/Navbar'
import { Route, Routes, useLocation } from 'react-router-dom'

import { useEffect, useState } from 'react'
import { Register,Models,Login,Contact,About,Home,Team,TestimonialsPage,Profile } from './Pages'

import LoadingCom from '../src/components/LoadingCom'
import Cookies from 'js-cookie'
import { useDispatch } from 'react-redux'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

function App() {
    const location = useLocation()
    const [showLoading, setShowLoading] = useState(true)
    const [isLogin, setIsLogin] = useState(false)

    const { pathname } = useLocation()

    const changeCoin = useDispatch()

    function logout() {
        setIsLogin(false)
    }

    useEffect(() => {
        //infoLocalStorage()

        setTimeout(() => {
            setShowLoading(false)
        }, 800)

        return () => {
            setShowLoading(true)
        }
    }, [location])

    useEffect(() => {
        if (Cookies.get('isLogin') && Cookies.get('isLogin') == 'true') {
            setIsLogin(true)
        }
        if (!Cookies.get('coin')) {

            Cookies.set('coin','DH')

        } else {

            changeCoin({type:"coin",payload:Cookies.get('coin')})

        }
    }, [])

    return (
        <>
            {showLoading && <LoadingCom />}

            {pathname !== '/login' && pathname != '/register' && <Navbar isLogin={isLogin} logout={logout} />}

            <Routes>
                <Route index path="/" element={<Home />} />
                <Route path="about" element={<About />} />
                <Route path="models/:tp?/:type?/:city?/:start?/:end?" element={<Models />} />
                <Route path="testimonials" element={<TestimonialsPage />} />
                <Route path="team" element={<Team />} />
                <Route path="contact" element={<Contact />} />

                <Route path="profile" element={<Profile />} />
                <Route path="login" element={<Login />} />
                <Route path="register" element={<Register />} />


                <Route index path="*" element={<Home />} />
            </Routes>
        </>
    )
}

export default App
