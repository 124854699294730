import React, { useEffect, useRef, useState } from 'react'
import '../dist/login.css'
import logo from '../images/logo/logo.png'
import bgXll from '../images/login/bgLogin.jpg'
import { Link } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import flags from '../data/flagCountry.json'
import { getFlagEmoji } from "../helper"


const apiUrl = process.env.REACT_APP_API_URL

function Login() {

    const [cCode,setCCode] = useState("212")
    const [flag,setFlag] = useState("MA")
    const userName = useRef()
    const Password = useRef()
    const navigate = useNavigate()

    function valid(user, pass) {
        let itsOk = true

        if (user === '') {
            itsOk = false

            toast.error("This didn't work. user", { style: { fontSize: 14 } })
        }

        if (pass === '') {
            itsOk = false

            toast.error("This didn't work. password", { style: { fontSize: 14 } })
        }

        return itsOk
    }

    function handleCodeCountry(e){

        setCCode(e.target.value)

        flags.forEach(el=>{
            
            if(el.dial_code.substring(1) === e.target.value)  setFlag(el.code)
        })

        //console.log(e.target.value)

    }

    function handleForm() {

        let mobile = userName.current.value.trim()
        let password = Password.current.value.trim()
        let country_code = cCode

        if (valid(mobile, password)) {
            //console.log(userName.current.value.trim(), Password.current.value.trim())

            axios
                .post(`${apiUrl}/auth/login`, { mobile, password, country_code })
                .then((res) => {
                    if (res.status === 200) {
                        Cookies.set('user', JSON.stringify(res.data))

                        navigate('/')
                    }
                })
                .catch((error) => {
                    if (error.message === 'Request failed with status code 422')
                        toast.error("This didn't work. user", { style: { fontSize: 14 } })
                })
        }
    }

    useEffect(() => {
        if (Cookies.get('user')) {
            navigate('/')
        }
    }, [])

    useEffect(() => {
        userName.current.focus()
        //console.log(flags)
    }, [])

    return (
        <div className="contianer_login" style={{ backgroundImage: `url(${bgXll})` }}>
            <div className="boxs">
                <div className="boxLogin">
                    <div className="logo">
                        <Link to="/">
                            <img src={logo} alt="-" width="100%" />
                        </Link>
                    </div>
                    <h2>Accueillir</h2>
                    <div className="inputs">
                        <div className="inputPhoneBox">
                            <div className="selectBox">
                                <select name="cars" value={cCode} id="cars" onChange={handleCodeCountry}>
                                    {flags.map((el, i) => {
                                        return <option key={i} value={el.dial_code.substring(1)}>{getFlagEmoji(el.code)} &nbsp; +{el.dial_code.substring(1)} </option>
                                    })}
                                </select>
                                <img src={`https://flagsapi.com/${flag}/flat/64.png`} />
                            </div>

                            <input
                                type="text"
                                name="username"
                                id="username"
                                placeholder="Numéro de tél."
                                ref={userName}
                            />
                        </div>
                        <input
                            type="password"
                            name="password"
                            id="password"
                            placeholder="Mot de passe"
                            ref={Password}
                        />
                        <input type="button" value="Se connecter" onClick={handleForm} />
                    </div>
                    <span className="line"></span>
                    <Link className="newAccount_login" to="/register">
                        Créer nouveau compte
                    </Link>
                </div>
            </div>

            <Toaster position="top-center" reverseOrder={false} />
        </div>
    )
}

export default Login
