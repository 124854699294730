import { Link } from 'react-router-dom'
//import BgShape from "../images/hero/hero-bg.png";
import BgShape from '../images/hero/hero-bg.png'
import HeroCar from '../images/hero/main-car.png'
import { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import AffectCove from './AffectCove'


function Hero() {
    const [goUp, setGoUp] = useState(false)

    const scrollToTop = () => {
        window.scrollTo({ top: (0, 0), behavior: 'smooth' })
    }

    const bookBtn = () => {
        document.querySelector('#booking-section').scrollIntoView({ behavior: 'smooth' })
    }

    useEffect(() => {
        const onPageScroll = () => {
            if (window.pageYOffset > 600) {
                setGoUp(true)
            } else {
                setGoUp(false)
            }
        }
        window.addEventListener('scroll', onPageScroll)

        return () => {
            window.removeEventListener('scroll', onPageScroll)
        }
    }, [])
    return (
        <>
            <section id="home" className="hero-section">
                <div className="container">
                    <AffectCove/>
                    <img className="bg-shape" src={BgShape} alt="bg-shape" />
                    <div className="hero-content">
                        <div className="hero-content__text">
                            <h4>Planifiez Votre Voyage dès Maintenant</h4>
                            <motion.h1
                                initial={{ opacity: 0, x: -400 }}
                                animate={{ opacity: 1, x: 0 }}
                                transition={{ duration: 1.6 }}>
                                Économisez En Grand avec <span>Notre</span> Service de Location de
                                Voiture
                                {/* Save <span>big</span> with our car rental */}
                            </motion.h1>
                            <motion.p
                            initial={{ opacity: 0, x: 200 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 1.6 }}
                            >
                                Préparez-vous à une expérience inoubliable en planifiant votre
                                voyage avec nous. Chez Ikcars, nous vous offrons bien plus qu'une
                                simple location de voiture. Découvrez le véhicule de vos rêves à des
                                tarifs imbattables, avec des avantages tels que des kilomètres
                                illimités, des options de prise en charge flexibles, et bien plus
                                encore.
                            </motion.p>
                            <motion.div className="hero-content__text__btns"
                            initial={{ opacity: 0, y: 200 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1.6 }}
                            >
                                <Link
                                    onClick={bookBtn}
                                    className="hero-content__text__btns__book-ride"
                                    to="/models">
                                    Réservez votre trajet &nbsp;
                                    <i className="material-symbols-outlined">arrow_forward</i>
                                </Link>
                                {/* <Link className="hero-content__text__btns__learn-more" to="/models">
                Modèles de véhicules &nbsp; <IconChevronRight />
                </Link> */}
                            </motion.div>
                        </div>

                        {/* img */}
                        <motion.img
                            initial={{ opacity: 0, y: -200 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 2 }}
                            src={HeroCar}
                            alt="car-img"
                            className="hero-content__car-img"
                        />
                    </div>
                </div>

                {/* page up */}
                <div onClick={scrollToTop} className={`scroll-up ${goUp ? 'show-scroll' : ''}`}>
                    <i className="material-symbols-outlined">arrow_upward</i>
                </div>
            </section>
        </>
    )
}

export default Hero
