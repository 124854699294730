import React, { useEffect, useRef, useState } from 'react'
import '../dist/login.css'
import '../dist/register.css'
import logo from '../images/logo/logo.png'
import bgXll from '../images/login/bgLogin.jpg'
import phoneIcone from '../images/login/phone-message.png'
import toast, { Toaster } from 'react-hot-toast'
import axios from 'axios'
import { useNavigate,Link } from 'react-router-dom'
import Cookies from 'js-cookie'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { countryCode } from '../data'

const apiUrl = process.env.REACT_APP_API_URL

function Register() {
    const Fname = useRef()
    const boxSms = useRef()

    const in1 = useRef()
    const in2 = useRef()
    const in3 = useRef()
    const in4 = useRef()
    const in5 = useRef()
    const in6 = useRef()

    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [password, setPassword] = useState('')
    const [password_2, setPassword_2] = useState('')
    const [codeCountry, setCodeCountry] = useState(212)

    const [userNameError, setUserNameError] = useState([false])
    const [PasswordError, setPasswordError] = useState([false])
    const [Password_2Error, setPassword_2Error] = useState([false])
    const [FNameError, setFNameError] = useState([false])
    const [LNameError, setLNameError] = useState([false])
    const [EmailError, setEmailError] = useState([false])
    const [PhoneError, setPhoneError] = useState([false])

    const [showPassword, setShowPassword] = useState(false)

    const [verificationCode, setVerificationCode] = useState(false)

    const [resendcode, setResendcode] = useState(true)

    const [errorInput,setErrorInput] = useState(false)

    const [loading,setLoading] = useState(false)

    const navigate = useNavigate()

    const handlefirstnameChange = (e) => {
        setFirstname(e.target.value.trim())
        setFNameError([false])
    }
    const handlelastnameChange = (e) => {
        setLastname(e.target.value.trim())
        setLNameError([false])
    }
    const handleusernameChange = (e) => {
        setUsername(e.target.value.trim())
        setUserNameError([false])
    }
    const handleemailChange = (e) => {
        setEmail(e.target.value.trim())
        setEmailError([false])
    }
    const handlepasswordChange = (e) => {
        setPassword(e.target.value.trim())
        setPasswordError([false])
        setPassword_2Error([false])
    }
    const handlepassword_2Change = (e) => {
        setPassword_2(e.target.value.trim())
        setPassword_2Error([false])
        setPasswordError([false])
    }
    const handleCodeCountry = (e) => {
        setCodeCountry(e.target.value)
    }

    const handlePhone = (e) => {
        setPhone(e.target.value)
        setPhoneError([false])
    }

    const validate = () => {
        let send = true

        if (!/^[a-zA-Z\s{0,1}]{3,}$/.test(firstname)) {
            send = false
            setFNameError([true])
        }
        if (!/^[a-zA-Z\s{0,1}]{3,}$/.test(lastname)) {
            send = false
            setLNameError([true])
        }
        if (!/^(06|05|04|07|08)\d{8}$/.test(phone)) {
            send = false
            setPhoneError([true])
        }
        if (!/^[a-zA-Z\s{0,1}]{3,}$/.test(username)) {
            send = false
            setUserNameError([true])
        }
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            send = false
            setEmailError([true])
        }

        if (!/\b\w{8,}\b/.test(password)) {
            send = false
            setPasswordError([true])
            setPassword_2Error([true])
        } else {
            if (password !== password_2) {
                send = false
                toast.error("This didn't work.", {
                    style: {
                        fontSize: '16px',
                    },
                })
                setPasswordError([true])
                setPassword_2Error([true])
            }
        }

        return send
    }

    function handleForm() {
        if (validate()) {
            //console.log(firstname, lastname, phone, password, password_2, email, codeCountry)

            setLoading(true)

            axios
                .post(
                    `${apiUrl}/auth/register`,
                    {
                        firstname: firstname,
                        lastname: lastname,
                        username: username,
                        email: email,
                        mobile: phone,
                        country_code: codeCountry,
                        password: password,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    },
                )
                .then((res) => {
                    //console.log(res.data)
                    if (res.status === 200) {
                        setVerificationCode(true)

                        Cookies.set('user', JSON.stringify(res.data))
                    }
                })
                .catch((error) => {
                    let list = {
                        username: setUserNameError,
                        email: setEmailError,
                        mobile: setPhoneError,
                    }

                    if (error.response.status === 422) {
                        //console.log(error.response.status)
                        //console.log(Object.keys(error.response.data.errors))
                        Object.keys(error.response.data.errors).forEach((el) => {
                            if (Object.keys(list).includes(el)) {
                                list[el]([true])
                                toast.error(error.response.data.errors[el], {
                                    style: {
                                        fontSize: '16px',
                                    },
                                })
                            }
                        })
                    }
                }).finally(()=>{
                    setLoading(false)
                })
        }
    }

    const handlResendcode = () => {

        //let token = '80|FjugSpwGaxR4OaH3NxQ1U0yYWibl50pOm1f5gstk'

        let token = JSON.parse(Cookies.get('user')).token

        //console.log(token)

        setResendcode(false)

        setTimeout(() => {
            setResendcode(true)
        }, 60000)

        axios.post(`${apiUrl}/otp/send`, {
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        }).then(res=>{
            //console.log(res)
        }).catch(error=>{
            //console.log(error)
        })
    }

    const handleInputSms = (e) => {
        //console.log(e.target.nextElementSibling)
        let next = e.target.nextElementSibling

        e.target.value = e.target.value.split('').at(-1) ? e.target.value.split('').at(-1) : ''

        if (next && e.target.value.match(/^[0-9]{1}$/)) {
            next.value = ''
            next.focus()
        } else {
            if (e.target.value) e.target.blur()
        }
    }

    const handleInputSmsKeyDown = (e) => {
        setErrorInput(false)
        if ((e.key === 'ArrowLeft' || e.key === 'Backspace') && e.target.previousElementSibling) {
            e.target.previousElementSibling.focus()
            e.target.value = ''
            e.target.previousElementSibling.value = ''
        }

        
    }

    const verification = () => {

        let token = JSON.parse(Cookies.get('user')).token

        setLoading(true)

        let otp = [
            in1.current.value,
            in2.current.value,
            in3.current.value,
            in4.current.value,
            in5.current.value,
            in6.current.value,
        ].join('')

        const postData = {
            otp,
        }

        axios
            .post(`${apiUrl}/otp/verify`, postData, {
                // //http://127.0.0.1:8000/api/otp

                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            })
            .then((res) => {
                //console.log(res)
                if (res.status === 200) {

                    toast.success('Successfully toasted!',{
                        style:{
                            fontSize:"14px"
                        }
                    })

                    setTimeout(() => {

                        navigate('/')
                        
                    }, 600);
                    
                } 
            })
            .catch((error) => {
                console.log(error)
                setErrorInput(true)
                toast.error("This didn't work.",{
                    style:{
                        fontSize:"14px"
                    }
                })
            }).finally(()=>{
                setLoading(false)
            })

        //console.log(otp)
    }


    useEffect(()=>{

        if (Cookies.get('user')) {

            //console.log(JSON.parse(Cookies.get('user')).token)

            navigate('/')
            
        }

    },[])



    useEffect(() => {

        if (Cookies.get('isLogin') && Cookies.get('isLogin') === 'true') {
            navigate('/')
        }

    }, [])

    useEffect(() => {
        Fname.current && Fname.current.focus()
    }, [])

    if (verificationCode) {

        //if (boxSms.current) console.log(boxSms.current)

        return (
            <div className="contianer_login" style={{ backgroundImage: `url(${bgXll})` }}>
                <div className="boxs boxs_register">
                    <div className="boxLogin">
                        {/* <button
                            className="btnBack"
                            onClick={() => {
                                setVerificationCode(false)
                            }}>
                            <i className="material-symbols-outlined">arrow_left_alt</i>
                        </button> */}
                        <div className="logo" style={{ height: '100px', width: '100px' }}>
                            <Link to="/">
                            <img src={phoneIcone} alt="-" width="100%" />
                            </Link>
                            
                        </div>
                        <h2>Saisissez le code de verification.</h2>
                        <p className="txtreg">
                            Nous venons tout juste d'envoyer un SMS avec un code de vérification au <strong>{phone ? phone : "000" }</strong> Saisissez ce code ci-dessous.
                        </p>
                        
                        <div className="boxSms" ref={boxSms}>
                            <input
                                ref={in1}
                                type="text"
                                name="n1"
                                id="n1"
                                onChange={handleInputSms}
                                onKeyDown={handleInputSmsKeyDown}
                                style={{borderColor:errorInput && "#ef4444"}}
                            />
                            <input
                                ref={in2}
                                type="text"
                                name="n2"
                                id="n2"
                                onChange={handleInputSms}
                                onKeyDown={handleInputSmsKeyDown}
                                style={{borderColor:errorInput && "#ef4444"}}
                            />
                            <input
                                ref={in3}
                                type="text"
                                name="n3"
                                id="n3"
                                onChange={handleInputSms}
                                onKeyDown={handleInputSmsKeyDown}
                                style={{borderColor:errorInput && "#ef4444"}}
                            />
                            <input
                                ref={in4}
                                type="text"
                                name="n4"
                                id="n4"
                                onChange={handleInputSms}
                                onKeyDown={handleInputSmsKeyDown}
                                style={{borderColor:errorInput && "#ef4444"}}
                            />
                            <input
                                ref={in5}
                                type="text"
                                name="n5"
                                id="n5"
                                onChange={handleInputSms}
                                onKeyDown={handleInputSmsKeyDown}
                                style={{borderColor:errorInput && "#ef4444"}}
                            />
                            <input
                                ref={in6}
                                type="text"
                                name="n6"
                                id="n6"
                                onChange={handleInputSms}
                                onKeyDown={handleInputSmsKeyDown}
                                style={{borderColor:errorInput && "#ef4444"}}
                            />
                        </div>
                        <button onClick={verification} className="btnSms">
                           
                           {
                             loading  ? "Chargement ..."  : "Envoyer"
                           }
                        </button>
                        <div className="resend_sms">
                            
                            {resendcode ? (
                                <strong onClick={handlResendcode}>je n'ai pas reçu le code</strong>
                            ) : (
                                <strong style={{ color: 'rgb(198, 198, 198)' }}>Envoyé ...</strong>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="contianer_login" style={{ backgroundImage: `url(${bgXll})` }}>
            <div className="boxs boxs_register">
                <div className="boxLogin">
                    <div className="logo">
                    <Link to="/">
                        <img src={logo} alt="-" width="100%" />
                        </Link>
                    </div>
                    <h2>Bienvenue Sur Ikcars</h2>
                    <div className="inputs inputs_register">
                        <input
                            className={FNameError[0] ? 'input inputError' : 'input'}
                            type="text"
                            name="firstname"
                            id="firstname"
                            placeholder="Prénom"
                            ref={Fname}
                            onChange={handlefirstnameChange}
                        />
                        <input
                            className={LNameError[0] ? 'input inputError' : 'input'}
                            type="text"
                            name="lastname"
                            id="lastname"
                            placeholder="Nom"
                            onChange={handlelastnameChange}
                        />
                        <input
                            className={userNameError[0] ? 'input_2 inputError' : 'input_2'}
                            type="text"
                            name="username"
                            id="username"
                            placeholder="username"
                            onChange={handleusernameChange}
                        />
                        <div className="showPassword phone_box">
                            <div className="code_country">
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={codeCountry}
                                    onChange={handleCodeCountry}
                                    sx={{ height: '40px', width: '100px', borderRadius: '6px' }}>
                                    {Object.keys(countryCode).map((el) => {
                                        return (
                                            <MenuItem
                                                key={el}
                                                sx={{ border: 0 }}
                                                value={countryCode[el]}>
                                                <span className="flag_country">
                                                    <img
                                                        src={`https://flagsapi.com/${el}/flat/24.png`}
                                                    />
                                                    + {countryCode[el]}
                                                </span>
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </div>
                            <input
                                className={PhoneError[0] ? 'input_2 inputError' : 'input_2'}
                                type="text"
                                name="phone"
                                id="username"
                                placeholder="Phone"
                                onChange={handlePhone}
                            />
                        </div>
                        <input
                            className={EmailError[0] ? 'input_2 inputError' : 'input_2'}
                            type="text"
                            name="email"
                            id="email"
                            placeholder="Email"
                            onChange={handleemailChange}
                        />

                        <div className="showPassword">
                            <input
                                className={PasswordError[0] ? 'input inputError' : 'input'}
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                                id="password"
                                placeholder="Mot de passe"
                                onChange={handlepasswordChange}
                            />
                            <button onClick={() => setShowPassword((e) => !e)}>
                                <i className="material-symbols-outlined">
                                    {showPassword ? 'visibility' : 'visibility_off'}
                                </i>
                            </button>
                        </div>

                        <div className="showPassword">
                            <input
                                className={Password_2Error[0] ? 'input inputError' : 'input'}
                                type={showPassword ? 'text' : 'password'}
                                name="password_2"
                                id="password_2"
                                placeholder="Confirmation mot de passe"
                                onChange={handlepassword_2Change}
                            />
                            <button onClick={() => setShowPassword((e) => !e)}>
                                <i className="material-symbols-outlined">
                                    {showPassword ? 'visibility' : 'visibility_off'}
                                </i>
                            </button>
                        </div>

                        <input className='btnNewAccount' type="button" value={loading ? "chargement ..." : "créer un compte"} onClick={handleForm} />
                    </div>
                </div>
            </div>

            <Toaster position="top-center" reverseOrder={false} />
        </div>
    )
}

export default Register
