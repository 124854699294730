import { IconMail, IconPhoneCall } from "@tabler/icons-react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <footer>
        <div className="container">
          <div className="footer-content">
            <ul className="footer-content__1">
              <li>
                {/* <span>CAR</span> Rental */}
                <span>Location</span> de voiture
              </li>
              <li>
              Découvrez notre vaste sélection de véhicules conçue pour répondre à toutes vos exigences de conduite. Quels que soient vos besoins, nous avons la voiture idéale qui allie style, confort et performance pour une expérience de conduite incomparable
              </li>
              <li>
                <a href="tel:212775779660">
                  <IconPhoneCall /> &nbsp; +212 775779660
                </a>
              </li>
              <li>
                <a href="tel:212525998870">
                  <IconPhoneCall /> &nbsp; +212 525998870
                </a>
              </li>

              <li>
                <a
                  href="mailto: 
                  support@ikcars.ma"
                >
                  <IconMail />
                  &nbsp; support@ikcars.ma
                </a>
              </li>

              {/* <li>
                <a
                  style={{ fontSize: "14px" }}
                  target="_blank"
                  rel="noreferrer"
                  href="#"
                >
                  Design by X
                </a>
              </li> */}
            </ul>

            <ul className="footer-content__2">
              <li>ENTREPRISE</li>
              <li>
              <Link to="/">
              Acceuil
              </Link>
              </li>
              <li>
                <Link to="/about">
                À propos
              </Link>
              </li>
              <li>
                
                <Link to="/models">
                véhicules
              </Link>
              </li>
              <li>
                
                <Link to="/contact">
                Contact
              </Link>
              </li>
              {/* <li>
                <a href="#home">Notre mode de fonctionnement .</a>
              </li> */}
            </ul>

            <ul className="footer-content__2">
              <li>Heures de travail</li>
              <li>Lun - Ven : 9h00 - 21h00</li>
              <li>Sam : 9h00 - 19h00 </li>
              <li>Dim : Fermé</li>
            </ul>

            <ul className="footer-content__2">
              <li>Abonnement</li>
              <li>
                <p>
                Restez connecté(e) et ne manquez aucune nouvelle ni mise à jour ! Inscrivez-vous dès maintenant avec votre adresse e-mail pour recevoir en avant-première les dernières informations
                </p>
              </li>
              <li>
                <input type="email" placeholder="Entrer l'adresse e-mail"></input>
              </li>
              <li>
                <button className="submit-email">inscription</button>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
