import { IconPhone } from "@tabler/icons-react";
import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";
import PlanTrip from "../components/PlanTrip";
import AboutMain from "../images/about/about-main.jpg";
import Box1 from "../images/about/icon1.png";
import Box2 from "../images/about/icon2.png";
import Box3 from "../images/about/icon3.png";

function About() {
  const PlanTripText = [
    {
      title:'Une Expérience de Location Transparente',
      content:'Nous croyons en la transparence et la simplicité. Avec notre processus de réservation convivial, vous pouvez rapidement choisir le véhicule qui correspond à vos besoins, sélectionner vos dates de location et finaliser votre réservation en quelques clics. Aucune surprise désagréable, juste une expérience de location sans tracas.'
    },
    {
      title:'Service Clientèle Exceptionnel',
      content:'Notre équipe dévouée de professionnels du service clientèle est là pour vous accompagner à chaque étape de votre expérience de location. Si vous avez des questions, des préoccupations ou simplement besoin de conseils, n\'hésitez pas à nous contacter. Via notre numéro de tel : +212 775779660 ou par mail : support@ikcars.ma  Votre satisfaction est notre priorité absolue.'
    },
    {
      title:'Options de Location Flexibles',
      content:'Que vous ayez besoin d\'une voiture pour un week-end, un voyage d\'affaires ou des vacances prolongées, nous avons des options de location flexibles pour répondre à vos exigences. Choisissez parmi une gamme de modèles, des citadines économiques aux SUV spacieux, pour trouver le véhicule parfait pour votre trajet.'
    }
  ]
  return (
    <>
      <section className="about-page">
        <HeroPages name="About" />
        <div className="container">
          <div className="about-main">
            <img
              className="about-main__img"
              src={AboutMain}
              alt="car-renting"
            />
            <div className="about-main__text">
              {/* <h3>About Company</h3> */}
              <h2>À propos de IKCARS&MOTO</h2>
              <p>
              Bienvenue chez IKCARS&MOTO, votre partenaire de confiance pour la location de voitures exceptionnelles. Notre mission est de simplifier vos déplacements en vous offrant un accès facile, rapide et abordable à une flotte diversifiée de véhicules de qualité supérieure.
              </p>
              <div className="about-main__text__icons">
                <div className="about-main__text__icons__box">
                  {/* <img src={Box1} alt="car-icon" /> */}
                  <i className="material-symbols-outlined">car_tag</i>
                  <span>
                    <h4>+10 Cars</h4>
                    {/* <p>Car Types</p> */}
                  </span>
                </div>
                <div className="about-main__text__icons__box">
                  {/* <img src={Box2} alt="car-icon" /> */}
                  <i className="material-symbols-outlined">home_pin</i>
                  <span>
                    <h4>+5 Villes</h4>
                    {/* <p>Rental Outlets</p> */}
                  </span>
                </div>
                <div className="about-main__text__icons__box">
                  {/* <img src={Box3} alt="car-icon" className="last-fk" /> */}
                  <i className="material-symbols-outlined">account_circle</i>
                  <span>
                    <h4>+34 Clients</h4>
                    {/* <p>Repair Shop</p> */}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <PlanTrip data={PlanTripText} />
        </div>
      </section>
      <div className="book-banner">
        <div className="book-banner__overlay"></div>
        <div className="container">
          <div className="text-content">
            <h2>Réservez Votre Voiture en Nous Contactant </h2>
            <span>
              <IconPhone width={40} height={40} />
              <h3>+212 775779660</h3>
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default About;
