import Img1 from "../images/download/appstore.svg";
import Img2 from "../images/download/googleapp.svg";

function Download() {
  return (
    <>
      <section className="download-section">
        <div className="container">
          <div className="download-text">
            <h2>Téléchargez notre application pour en tirer le meilleur parti</h2>
            {/* <p>
            Jeté timide désigne dix dames bien que demandé vu. Ou bien par lui aller penser ordonner événement musique. Incommoder donc l'intention défectueuse convaincue. Mené revenu mois lui-même et maisons vous.
            </p> */}
            <div className="download-text__btns">
              <img alt="download_img" src={Img2} />
              <img alt="download_img" src={Img1} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Download;
