//import SelectCar from "../images/plan/icon1.png";
import SelectCar from "../images/icon-info/f-2.png";
import Contact from "../images/icon-info/f-3.png";
import Drive from "../images/icon-info/f-1.png";

function PlanTrip({data}) {
  return (
    <>
      <section className="plan-section">
        <div className="container">
          <div className="plan-container">
            <div className="plan-container__title">
              <h3>Planifiez Votre Aventure Dès Maintenant</h3>
              <h2>Location de Voiture Rapide et Facile</h2>
            </div>

            <div className="plan-container__boxes">
              <div className="plan-container__boxes__box">
                <img src={SelectCar} alt="icon_img" />

                <h3>{data[0].title}</h3>
                <p>
                Nous croyons en la transparence et la simplicité. Avec notre processus de réservation convivial, vous pouvez rapidement choisir le véhicule qui correspond à vos besoins, sélectionner vos dates de location et finaliser votre réservation en quelques clics. Aucune surprise désagréable, juste une expérience de location sans tracas.
                </p>
              </div>

              <div className="plan-container__boxes__box">
                <img src={Contact} alt="icon_img" />
                <h3>{data[1].title}</h3>
                <p>
                Notre équipe dévouée de professionnels du service clientèle est là pour vous accompagner à chaque étape de votre expérience de location. Si vous avez des questions, des préoccupations ou simplement besoin de conseils, n'hésitez pas à nous contacter. Via notre numéro de tel : +212 775779660 ou par mail : support@ikcars.ma  Votre satisfaction est notre priorité absolue.
                </p>
              </div>

              <div className="plan-container__boxes__box">
                <img src={Drive} alt="icon_img" />
                <h3>{data[2].title}</h3>
                <p>
                Que vous ayez besoin d'une voiture pour un week-end, un voyage d'affaires ou des vacances prolongées, nous avons des options de location flexibles pour répondre à vos exigences. Choisissez parmi une gamme de modèles, des citadines économiques aux SUV spacieux, pour trouver le véhicule parfait pour votre trajet.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PlanTrip;
