import { legacy_createStore as createStore } from 'redux'

export const reduser = {
    data:{
        coin:"DH"
    }
}


const store = createStore((state = reduser, action) => {

    if (action.type === "coin") {

        return {...state,data:{...state.data,coin:action.payload}}
        
    }

    return state
})

export default store




