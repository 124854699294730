import { useNavigate, Link } from 'react-router-dom'
import Logo from '../images/logo/logo.png'
import { useEffect, useRef, useState } from 'react'
import { IconMenu2, IconX } from '@tabler/icons-react'
import Cookies from 'js-cookie'
import Avatar from '@mui/material/Avatar'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'

import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'

import imgMAD from '../images/flags/MAD.png'
import imgEUR from '../images/flags/EUR.png'
import imgUSD from '../images/flags/USD.png'

import { useSelector, useDispatch } from 'react-redux'

function Navbar() {
    const [nav, setNav] = useState(false)

    const [isLogin, setLogin] = useState(false)

    const btnNav = useRef(null)

    const { coin } = useSelector((state) => state.data)
    const changeCoin = useDispatch()

    const navigate = useNavigate()

    const openNav = () => {
        setNav(!nav)
    }

    useEffect(() => {
        if (Cookies.get('user')) {
            setLogin(true)
        }
        console.log(coin)
    }, [])

    const logout = () => {
        Cookies.remove('user')
        setLogin(false)
        setNav(false)
        navigate('/')
    }

    const handleCoin = (event) => {
        changeCoin({ type: 'coin', payload: event.target.value })
        Cookies.set('coin', event.target.value)
    }

    const handleCoinBtn = (event) => {
        changeCoin({ type: 'coin', payload: event })
        Cookies.set('coin', event)
        btnNav.current.click()
    }

    const CoinComponent = ({ handleCoin }) => {
        return (
            <FormControl sx={{ m: 1, width: 120, zIndex: 9999999, border: 0 }} size="small">
                <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={coin}
                    onChange={handleCoin}
                    sx={{
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#0000',
                        },
                        '& .MuiSvgIcon-root': {
                            color: '#0000',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#0000',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#0000',
                        },
                    }}>
                    <MenuItem value="DH">
                        <img className="img_coin" width={20} src={imgMAD} /> MAD
                    </MenuItem>
                    <MenuItem value="USD">
                        <img className="img_coin" width={20} src={imgUSD} />
                        USD
                    </MenuItem>
                    <MenuItem value="EUR">
                        <img className="img_coin" width={20} src={imgEUR} />
                        EUR
                    </MenuItem>
                </Select>
            </FormControl>
        )
    }

    return (
        <>
            <nav>
                {/* mobile */}
                <div className={`mobile-navbar ${nav ? 'open-nav' : ''}`}>
                    <div ref={btnNav} onClick={openNav} className="mobile-navbar__close">
                        <IconX width={30} height={30} />
                    </div>
                    <ul className="mobile-navbar__links">
                        <li>
                            <Link onClick={openNav} to="/">
                                Acceuil
                            </Link>
                        </li>
                        <li>
                            <Link onClick={openNav} to="/about">
                                À propos
                            </Link>
                        </li>
                        <li>
                            <Link onClick={openNav} to="/models/car">
                                voiture
                            </Link>
                        </li>
                        <li>
                            {' '}
                            <Link className="testi-link" to="/models/moto">
                                moto
                            </Link>
                        </li>
                        {/* <li>
              <Link onClick={openNav} to="/team">
                Our Team
              </Link>
            </li> */}

                        <li>
                            <Link onClick={openNav} to="/contact">
                                Contact
                            </Link>
                        </li>

                        {!isLogin ? (
                            <>
                                <li>
                                    <Link onClick={openNav} to="/register">
                                        Registre
                                    </Link>
                                </li>
                                <li>
                                    <Link onClick={openNav} to="/login">
                                        Se connecter
                                    </Link>
                                </li>
                            </>
                        ) : (
                            <>
                                <li>
                                    <Link onClick={openNav} to="/profile">
                                        profile
                                    </Link>
                                </li>
                                <li>
                                    <button onClick={logout} className="logout_mobile">
                                        Se déconnecter
                                    </button>
                                </li>
                            </>
                        )}
                        <li>devise</li>
                        <li className="li_btns_coins">
                            <button onClick={()=>handleCoinBtn('DH')}>
                                <img className={coin === 'DH' ? 'active' : ''} src={imgMAD} />
                            </button>
                            <button onClick={()=>handleCoinBtn('EUR')}>
                                <img className={coin === 'EUR' ? 'active' : ''} src={imgEUR} />
                            </button>
                            <button onClick={()=>handleCoinBtn('USD')}>
                                <img className={coin === 'USD' ? 'active' : ''} src={imgUSD} />
                            </button>
                        </li>
                    </ul>
                </div>

                {/* desktop */}

                <div className="navbar">
                    <div className="navbar__img">
                        <Link to="/" onClick={() => window.scrollTo(0, 0)}>
                            <img src={Logo} alt="logo-img" />
                        </Link>
                    </div>
                    <ul className="navbar__links">
                        <li>
                            <Link className="home-link" to="/">
                                Acceuil
                            </Link>
                        </li>
                        <li>
                            {' '}
                            <Link className="about-link" to="/about">
                                À propos
                            </Link>
                        </li>
                        <li>
                            {' '}
                            <Link className="models-link" to="/models/car">
                                voiture
                            </Link>
                        </li>
                        <li>
                            {' '}
                            <Link className="testi-link" to="/models/moto">
                                moto
                            </Link>
                        </li>

                        {/* <li>
              {" "}
              <Link className="team-link" to="/team">
                Our Team
              </Link>
            </li> */}

                        <li>
                            {' '}
                            <Link className="contact-link" to="/contact">
                                Contact
                            </Link>
                        </li>
                        <li>
                            <CoinComponent handleCoin={handleCoin} />
                        </li>
                    </ul>
                    {!isLogin ? (
                        <div className="navbar__buttons">
                            <Link className="navbar__buttons__sign-in" to="/login">
                                Se connecter
                            </Link>
                            <Link className="navbar__buttons__register" to="/register">
                                Registre
                            </Link>
                        </div>
                    ) : (
                        <div className="navbar__buttons navbar__buttons_login">
                            <button onClick={logout} className="navbar__buttons__register">
                                Se déconnecter
                            </button>
                            <Link className="box_avater_profile" to="/profile">
                                <Avatar sx={{ bgcolor: '#d0a14936' }}>
                                    <AccountCircleIcon
                                        sx={{ fontSize: '40px', color: '#d0a149' }}
                                    />
                                </Avatar>
                            </Link>
                        </div>
                    )}

                    {/* mobile */}

                    <div className="mobile-hamb" onClick={openNav}>
                        <IconMenu2 width={30} height={30} />
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Navbar
