import Hero from "../components/Hero";
import BookCar from "../components/BookCar";
import PlanTrip from "../components/PlanTrip";
import PickCar from "../components/PickCar";
import Banner from "../components/Banner";
import ChooseUs from "../components/ChooseUs";
import Testimonials from "../components/Testimonials";
import Faq from "../components/Faq";
import Download from "../components/Download";
import Footer from "../components/Footer";
import SliderBrand from "../components/SliderBrand";

function Home() {

  const PlanTripText = [
    {
      title:'Une Expérience de Location Transparente',
      content:'Une location sans souci: choisissez, réservez et partez en quelques clics. Transparent et simple, sans surprises.'
    },
    {
      title:'Service Clientèle Exceptionnel',
      content:'Service clientèle exceptionnel : contactez-nous pour toute question ou préoccupation. Appelez au +212 775779660 ou écrivez à support@ikcars.ma.'
    },
    {
      title:'Options de Location Flexibles',
      content:'Options de location flexibles pour tous vos besoins : du week-end aux vacances prolongées, choisissez parmi une variété de modèles de voitures pour votre trajet idéal. Découvrez nos modèles de véhicules.'
    }
  ]
  
  return (
    <>
      <Hero />
      <BookCar />
      <SliderBrand/>
      <PlanTrip data={PlanTripText} />
      <PickCar />
      <Banner />
      <ChooseUs />
      <Testimonials />
      <Faq />
      <Download />
      <Footer />
    </>
  );
}

export default Home;
